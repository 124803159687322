<template>
  <LayoutWithoutMap>
    <template #title> Réseaux </template>
    <template #body>
      <div class="w-full">
        <template v-if="items.length != 0">
          <div
            class="grid grid-cols-3 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 ws:grid-cols-4 full-hd:grid-cols-4 gap-y-8 gap-x-8"
          >
            <Reseaux
              v-for="(item, index) in items"
              :key="index"
              :status="item.status"
              :reseauxDegree="item.reseauxDegree"
              :type="item.type"
            />
          </div>
        </template>
        <empty-state
          v-else
          class="h-4/5 mt-7 bg-transparent"
          :message="'Ces informations ne sont pas disponibles pour cette adresse'"
          :main_icon="'bot1'"
          classes_icon="!w-1/4 !h-1/4 sm:!w-48 sm:!h-48"
        />
      </div>
    </template>
  </LayoutWithoutMap>
</template>
<script>
import helpers from '@/mixins/helpers'
import LayoutWithoutMap from '../LayoutWithoutMap'
import { mapGetters } from 'vuex'
export default {
  components: {
    LayoutWithoutMap,
  },
  data() {
    return {
      items: [],
    }
  },
  mixins: [helpers],
  methods: {
    addReseau(key, name) {
      let score = this.getNestedObject(this.poi.data, 'reseaux', key, 'score')
      if (this.isExist(score)) {
        const { reseauxDegree, status } = this.getReseauxDegreeAndStatus(score)
        this.items.push({
          type: name,
          reseauxDegree: reseauxDegree,
          status: status,
        })
      }
    },
    getReseauxDegreeAndStatus(score) {
      const reseauxDegree = this.round(score / 20)
      let status = ''
      if (reseauxDegree <= 2) {
        status = 'Mauvais'
      } else if (reseauxDegree < 4) {
        status = 'Moyenne'
      } else {
        status = 'Bien'
      }
      return {
        reseauxDegree,
        status,
      }
    },
    init() {
      this.addReseau('internet_haut_debit', 'HD')
      this.addReseau('fibre', 'Fibre')
      this.addReseau('mobile_4g', '4G')
      this.addReseau('mobile_3g', '3G')
    },
  },
  computed: {
    ...mapGetters({
      poi:'address/getPOI'
    }),
  },
  created() {
    this.init()
  },
  beforeRouteLeave(to, from, next) {
    this.$destroy()
    next()
  },
}
</script>
